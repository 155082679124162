(function($) {
var Sage = {
	'common': {
		init: function() {},
		finalize: function() {

			// Recalculate Footer Height
			jQuery('body').css('padding-bottom', jQuery('footer').outerHeight());
			jQuery(window).on('resize', function(){
				jQuery('body').css('padding-bottom', jQuery('footer').outerHeight());
			});

			// Headroom
			var myElement = document.querySelector(".menu");
			var headroom  = new Headroom(myElement);
			headroom.init();

			// Menu Mobile
			jQuery('.menu-mobile').click(function(){
				jQuery(this).toggleClass('active');
				if(!jQuery(this).hasClass('active')){
					jQuery('.mobile-menu').slideUp();
				} else {
					jQuery('.mobile-menu').slideDown();
				}
			});

			// Menu Scroll Selector
			var menuSection = $('.section'), navLists = $('.main-menu ul li'),
			navLists_height = navLists.outerHeight(), headerOffset = $('.header').offset().top;
			$(window).on('scroll', function () {
				var cur_position = $(this).scrollTop() + 70;
				menuSection.each(function() {
					var top = $(this).offset().top - navLists_height,
						bottom = top + $(this).outerHeight();
					if (cur_position >= top && cur_position <= bottom) {
						navLists.find('a').removeClass('active');
						menuSection.removeClass('active');
						$(this).addClass('active');
						navLists.find('a[href="#'+$(this).attr('id')+'"]').addClass('active');
					}
				});
			});
			navLists.find('a').on('click', function () {
				var $el = $(this), id = $el.attr('href');
				$('html, body').animate({
					scrollTop: $(id).offset().top - navLists_height
				}, 500);
				return false;
			});

			// Tooltips
			jQuery('[data-toggle="tooltip"]').tooltip(); 

			// Accordion
			jQuery('.accordion .accordion-content').slideUp();
			jQuery('.accordion .accordion-header').each(function(){
				jQuery(this).click(function(){
					if(!jQuery(this).hasClass('active')){
						jQuery('.accordion .accordion-header').removeClass('active');
						jQuery(this).addClass('active');
						jQuery('.accordion .accordion-header').next().slideUp();
						jQuery(this).next().slideDown();
					} else {
						jQuery(this).removeClass('active');
						jQuery(this).next().slideUp();
					}
				});
			});

		}
	},
	'home': {
		init: function() {},
		finalize: function() {

			jQuery('.main-menu ul li').find('a').each(function() {
				var link = jQuery(this).attr('href');
				if (link.indexOf('#') > -1){
					var id = link.substring(link.lastIndexOf('#') + 1);
					jQuery(this).attr('href','#'+id);
				}
			});

		}
	}
};

// ----------------------------------------------------------------------------
var UTIL = {
fire: function(func, funcname, args) {
	var fire;
	var namespace = Sage;
	funcname = (funcname === undefined) ? 'init' : funcname;
	fire = func !== '';
	fire = fire && namespace[func];
	fire = fire && typeof namespace[func][funcname] === 'function';
	if (fire) {
		namespace[func][funcname](args);
	}
},
loadEvents: function() {
	UTIL.fire('common');
	$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	});
	UTIL.fire('common', 'finalize');
	}
};
jQuery(document).ready(UTIL.loadEvents);
})(jQuery);